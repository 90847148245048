@import "~react-pro-sidebar/dist/scss/styles.scss";
input {
  &:focus {
    outline: none;
    box-shadow: none;
  }
}

.header-bar-wrapper {
  border-bottom: none;
  .navbar-breadcrumb {
    background: #fafafa;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 15px;
    padding-bottom: 15px;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0px 1px 7px #b7c0cd;
    .navbar-links-wrapper {
      .menubarbtn {
        background: transparent;
        padding: 0px;
        border: none;
        box-shadow: none;
        svg {
          width: 35px;
          font-size: 35px;
          fill: #212529;
        }
      }
    }
    .navbar-menu-wrapper {
      .btn {
        background: #212529;
        border: 1px solid #212529;
        box-shadow: none;
        &:focus {
          background: #212529;
          border: 1px solid #212529;
          box-shadow: none;
        }
      }
    }
  }
  .logo-field {
    h1 {
      font-size: 20px;
      color: #ffffff;
    }
  }
}
.layout-wrapper {
  display: flex;
  height: 100%;
  min-height: 100vh;
  .sidebar-wrapper {
    width: auto;
    .sidebar {
      margin: 0px;
      background: #212529;
      border: none;
      border-radius: 0px;
      width: 100%;
      padding-top: 15px;
      ul {
        li {
          a {
            color: #b7c0cd;
            padding-left: 15px;
            span {
              color: #ffffff;
            }
          }
        }
      }
    }
    .sidebarmenubar {
      height: 100%;
      .sidebarmenulist {
        height: 100%;
        .pro-sidebar {
          &.collapsed {
            .logo-field {
              h1 {
                font-size: 13px !important;
                text-align: center !important;
              }
            }
          }
          .pro-sidebar-inner {
            background: #212529;
          }
        }
        .pro-sidebar-header {
          padding: 0px 15px;
          .logo-field {
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 75px;
            h1 {
              color: #ffffff;
              font-size: 25px;
              margin-bottom: 0px;
            }
            .menubarbtn {
              background: transparent;
              box-shadow: none;
              border: none;
              padding: 0px;
              &:focus,
              &:active {
                box-shadow: none;
              }
            }
          }
        }
        .pro-sidebar-content {
          padding-top: 15px;
          .pro-menu-item {
            span.pro-icon-wrapper {
              background: transparent;
              .pro-icon {
                padding: 0px;
                svg {
                  width: 25px;
                  font-size: 25px;
                }
              }
            }
            .pro-item-content {
              color: #ffffff;
              font-size: 16px;
            }
            &.active {
            }
          }
          .pro-menu {
            padding: 0px;
          }
        }
      }
    }
  }
  .layout-content-wrapepr {
    width: calc(100% - 270px);
    background: #f6f7fb;
    .warmup-content-wrappper {
      padding: 20px 40px;
      height: calc(100% - 50px);
      .plan-wrapper {
        margin-top: 40px;
        border-radius: 5px;
        -webkit-box-shadow: 0 1px 20px 0 rgba(69, 90, 100, 0.18);
        box-shadow: 0 1px 20px 0 rgba(69, 90, 100, 0.18);
        border: none;
        margin-bottom: 30px;
        background-color: #fff;
        padding: 20px;
        .plan-table-heading {
          display: flex;
          width: 100%;
          justify-content: space-between;
          margin-bottom: 20px;
          align-items: center;
          h3 {
            font-size: 25px;
            margin: 0;
          }
          .search-bar {
            max-width: 400px;
            width: 100%;
            position: relative;
            i {
              &.fa-search {
                position: absolute;
                top: 11px;
                left: 10px;
                opacity: 0.2;
              }
            }
            input {
              width: 100%;
              min-width: auto;
              padding: 0 0 0 30px;
              height: 41px;
              border-radius: 5px;
              background: #f9f9f9;
            }
          }
          .addpopup-btn {
            .btn {
              background: #fc1f1f;
              border-color: #fc1f1f;
              box-shadow: none;
            }
          }
          .btn {
            background: #fc1f1f;
            border-color: #fc1f1f;
            box-shadow: none;
          }
        }
        .plan-table {
          table {
            thead {
              th {
                background: #212529;
                color: #ffffff;
                border-color: #f4f5fa;
                border-top-left-radius: 3px;
                border-top-right-radius: 3px;
                padding: 15px 14px;
                font-size: 15px;
              }
            }
            tbody {
            }
          }
          .btn {
            background: transparent;
            border: none;
            box-shadow: none;
            padding: 0px;
            margin: 0px 5px;
            i {
              color: #212529;
            }
            &.user-activation {
              background-color: #212529 !important;
              color: #ffffff;
              padding: 7px;
            }
            &.user-dctivation {
              background: #0b51c5;
              padding: 7px;
            }
          }
        }
        .pagination-box {
          margin-top: 20px;
          .pagination {
            justify-content: flex-end;
          }
        }
      }
    }
  }
  .layout-content-wrapepr.newClass {
    width: calc(100% - 80px);
  }
}
.questions-tab {
  .plan-table-heading {
    flex-direction: column;
    .questionTitlebtn {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 30px;
      border-bottom: 1px solid #eee;
      padding-bottom: 25px;
    }
    .question-table-head {
      text-align: right;
      margin-bottom: 20px;
      .search-bar {
        max-width: 430px !important;
        display: inline-block;
        .question-select {
          display: flex;
          border: 1px solid #eee;
          border-radius: 10px;
          background: #f9f9f9;
          padding: 4px 10px;
          position: relative;
          align-items: center;
          select {
            &.input1 {
              font-size: 14px;
              margin-left: 20px;
              border: 0;
              background: transparent;
              border-radius: 0;
              padding: 3px 0;
              &.select-2 {
                margin-left: 30px;
              }
            }
          }
          &:after {
            content: "";
            position: absolute;
            width: 1px;
            height: 10px;
            background: #a9a1a1;
            left: 53%;
            top: 15px;
          }
        }
      }
    }
  }
}

.popup-form-box {
  margin-bottom: 10px;
}
.modulePopup-edit {
  .modal-dialog {
    .login1 {
      width: 100%;
      position: static;
      .popup-form-box {
        label {
          display: block;
          text-align: left;
          margin-bottom: 6px;
          margin-top: 5px;
          font-weight: 500;
          font-size: 14px;
        }
      }
    }
    .modal-title {
      &.h4 {
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 10px;
        text-align: left;
      }
    }
  }
}
.fade.modal .login1 {
  position: static;
  width: 100%;
}
.warmup-modal {
  .modal-content {
    border: none;
    .modal-body {
      padding-left: 0px;
      padding-right: 0px;
    }
  }
}

.modal-footer {
  .btn {
    & + .btn {
      background-color: #212529 !important;
      border-color: #212529 !important;
      box-shadow: none !important;
      &:focus,
      &:active {
        background-color: #212529 !important;
        border-color: #212529 !important;
        box-shadow: none !important;
      }
    }
  }
  button[type="submit"] {
    background: #212529;
    border-color: #212529;
    box-shadow: none;
    &:focus,
    &:active {
      background: #212529 !important;
      border-color: #212529 !important;
      box-shadow: none !important;
    }
  }
  button[type="button"] {
    background-color: #fc1f1f !important;
    border-color: #fc1f1f !important;
    box-shadow: none !important;
    &:focus,
    &:active {
      background-color: #fc1f1f !important;
      border-color: #fc1f1f !important;
      box-shadow: none !important;
    }
  }
}
.modal-header {
  padding: 15px 25px;
}
.popup-form-box {
  /*padding: 0px 10px;*/
}

.warmup-content {
  min-height: 590px;
  .table-pagination {
    padding: 0px 40px;
    ul {
      justify-content: flex-end;
    }
  }
}
.login1 {
  width: 400px;
  background: #fff;
  border: 1px solid #dddfe2;
  box-shadow: 0 2px 4px rgb(0 0 0 / 10%), 0 8px 16px rgb(0 0 0 / 10%);
  border-radius: 8px;
  padding: 1rem;
  align-items: center;
  text-align: center;
  position: absolute;
  top: 20%;
  left: 30%;
}

.input1,
select {
  border-radius: 5px;
  border: 2px solid #dddfe2;
  outline: none;
  color: #1d2129;
  margin: 0.1rem 0;
  padding: 0.5rem 0.75rem;
  width: 100%;
  font-size: 1rem;
  &:focus {
    border: 2px solid #dddfe2;
  }
}
.login1 > .button {
  background: #1877f2;
  border: 1px solid #1877f2;
  color: #fff;
  font-size: 1.25rem;
  padding: 0.5rem;
  margin: 0.5rem 0;
  border-radius: 8px;
  outline: none;
  cursor: pointer;
  width: 320px;
  &:focus {
    box-shadow: none;
  }
}
.importpopup {
  .btn {
    background: #fc1f1f;
    border-color: #fc1f1f;
    box-shadow: none;
    margin-bottom: 15px;
  }
  & + .plan-table {
    margin-top: 0px;
    width: 100%;
    display: inline-block;
  }
}
.user-modal {
  .modal-content {
    border: none;
  }
  label.form-label {
    width: 100%;
    text-align: left;
  }
  .login1 {
    padding: 0px;
    border: none;
  }
  .modal-header {
    background: #212529;
    color: #ffffff;
    border: none;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    padding: 15px 14px;
  }
  .popup-form-box {
    .form-filed_course {
      .form-group {
        margin-bottom: 10px;
        label {
          margin-bottom: 5px;
          color: #001737;
          font-weight: 400;
          font-size: 16px;
        }
        #csvFileInput {
          padding: 10px;
          border-radius: 5px;
          border: 2px solid #dddfe2;
          outline: none;
          color: #1d2129;
          width: 100%;
        }
      }
    }
  }
}
.loginbox {
  .form-group {
    label {
      text-align: left;
      width: 100%;
      font-size: 17px;
      font-weight: 500;
    }
    input {
      margin-bottom: 0px !important;
    }
    .error {
      color: red;
      margin-top: 5px;
      font-size: 13px;
      width: 100%;
      text-align: left;
      display: block;
    }
  }
}
.error {
  color: red;
  margin-top: 3px;
  font-size: 13px;
  width: 100%;
  text-align: left;
  display: block;
}
.dropdown {
  a {
    display: block;
  }
}
.profile-login-layout {
  .profilelogin {
    .form-group,
    .form-filed_course {
      margin-bottom: 20px;
      input {
        margin-bottom: 0px !important;
      }
      label {
        font-size: 16px;
        font-weight: 500;
      }
    }
  }
}

.Toastify__toast-body {
  div {
    justify-content: start;
    text-align: left;
  }
}
.table-responsive {
  max-height: 400px;
}
.table-responsive::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
}

.table-responsive::-webkit-scrollbar {
  width: 10px;
}

.table-responsive::-webkit-scrollbar-thumb {
  border-radius: 5px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #212529;
}
select[name="count"] {
  padding: 4px 5px;
  border: 1px solid #dadada;
  font-weight: 400;
  font-size: 0.875rem;
  border-radius: 5px;
  margin: 15px 5px 0px;
  background: #f9f9f9;
}

.pas-word {
  position: relative;
  .password-btn {
    background-color: transparent;
    padding: 0px;
    border: none;
    width: auto;
    position: absolute;
    top: 12px;
    right: 10px;
    box-shadow: none;
    height: auto;
    &.notshow {
      opacity: 0.7;
      &:after {
        content: "";
        border-bottom: 2px solid #1c5141;
        width: 25px;
        height: 2px;
        position: absolute;
        top: 11px;
        right: -1px;
        transform: rotate(45deg);
      }
    }
    svg {
      font-size: 22px;
      fill: #1c5141;
    }
  }
}
.notification-wrapper {
  position: absolute;
  top: 76px;
  right: 0;
  width: 400px;
  z-index: 9999;
  background-color: #f8f8f8;
  transform:translateY(-120%);
  transition: transform 0.5s ease-in-out;
  &-enter {
    transform: translateY(0%);
    &-done {
      transform: translateY(0%);
    }
  }
  &-exit {
    transform: translateY(100%);
    &-active {
      transform: translateY(-120%);
    }
  }
}

.open-notification{
  overflow-x: hidden;
}
.notification-wrapper{

 
.notification-box {

  .heading{
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #dddddd;
    .btn{
      margin-right: 16px;
      svg{
        fill: #333333;
        font-size: 20px;
      }
      &:hover,&:focus,&:active{
        background: transparent!important;
        border: none!important;
      }
    }
  }
  h2{
    font-family: "Nunito Sans", sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
      color: #404040;
      border-bottom: 1px solid #D9D9D9;
      padding: 15px 20px 15px 25px;
      margin-bottom: 0px;
  }
  .notification-list-box{
      max-height: 350px;
  overflow-y: auto;
  padding-right: 4px;
  width: calc(100% - 3px);
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
      .notification-lists{
          display: flex;
          align-items: center;
          justify-content: space-between;
          border-bottom: 1px solid #D9D9D9;
          padding:10px 20px 10px 20px;
          background: #fbfbfb;
          position: relative;
          &:hover{
            background: #f9f9f9!important;
        }
          &:last-child{
              border-bottom: none;
          }
          &.nonreadble{
            background-color: #efefef;
            
          }
          .notification-detail{
              padding-right: 10px;
              display: flex;
              align-items: baseline;
              .nth-profileicon{
                margin-right: 10px;
                img{
                  border-radius: 50px;
                  width: 30px;
                  height: 30px;
                  min-width: 30px;
                  object-fit: cover;
                }
              }
              h3{
                  font-style: normal;
                  font-family: "Poppins", sans-serif;
                  font-weight: 500;
                  font-size: 15px;
                  line-height: 18px;
                  color: #333333;
                  margin-bottom:4px;
              }
              p{
                  font-style: normal;
                  font-family: "Poppins", sans-serif;
                  font-weight:400;
                  font-size: 14px;
                  letter-spacing: 0.1px;
                  line-height: 21px;
                  color: #333333;
                  margin-bottom: 4px;
              }
              ul{
                  margin: 0px;
                  padding: 0px;
                  display: flex;
                  align-items: center;
                  li{
                      margin-right: 34px;
                      margin-left: 0px;
                      position: relative;
                      list-style: none;
                      &::after{
                          content: '';
                          background-color: #333333;
                          width: 6px;
                          height:6px;
                          position: absolute;
                          top: 11px;
                          right: -20px;
                          margin: auto;
                          border-radius: 50px;
                      }
                      &:last-child{
                          &::after{
                              content: initial;
                          }
                      }
                      span{
                        font-family: "Poppins", sans-serif;
                          font-style: normal;
                          font-weight: 400;
                          font-size: 12px;
                          line-height: 18px;
                          color:#145340;
                      }
                  }
              }
          }
          .btn{
              svg{
                  font-size: 18px;
              }
          }
      }
      
  }
  .notfiction-btn{
      padding:10px 20px;
      text-align: center;
      border-top: 1px solid #D9D9D9;
      background-color: #14513e;
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;
      .btn{
          color: #f5f5f5;
          text-transform: capitalize;
          font-size: 16px;
          font-weight: 500;
      }
  }
  .btn{
      background-color: transparent;
      outline: none;
      box-shadow: none;
      padding: 0px;
      border: none;
      
  }
}
}
.notification-btn {
  background-color: transparent!important;
  border: none!important;
  padding: 0px;
  position: relative;
  margin-right: 30px;
  svg{
    fill: #292929;
    font-size: 25px;
  }
  &:hover,&:focus,&:active,&:focus-visible{
    background-color: transparent!important;
    border: none!important;
  }
  .notification-value{
    position: absolute;
    background-color: #0c5543;
    color: #fff;
    border-radius: 50px;
    font-size: 10px;
    padding: 0;
    right: -10px;
    top: -8px;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.notification-list-box::-webkit-scrollbar-track{

  border-radius: 10px;
  background-color: transparent;
}
.notification-list-box::-webkit-scrollbar {
  width: 4px;
  background-color: transparent;
}
.notification-list-box::-webkit-scrollbar-thumb{
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  background-color: #145340;
}


.notification-layout {
  margin-top: 30px;
  .main-heading {
      border-bottom: none !important;
      margin-left: 16px;
      h1 {
        font-size: 25px;
          b {
              color: #e75d09;
              font-size: 23px;
              font-weight: 500;
              font-family: "Rubik", sans-serif;
              margin-left: 4px;
          }
      }
  }

  .notification-list-wrap {
      background-color: #f7f7f7;
      padding: 20px;
      margin-top:10px;
      .loader{
        .loading{
            left: 0px!important;
        }
    }
      .notification-lists{
        &.nonreadble{
            .ntf-list{
              .nth-list-wrap{
                .nth-message-list{
                  background-color:#ebe9e9;
                }
              }
            }
          }
    }
      .Notification-tab-list{
          margin-bottom: 30px;
          margin-top: 10px;
          .btn{
              background: transparent;
              border: 1px solid #134f3d;
              color: #134f3d;
              margin-right: 16px;
              font-size: 14px;
              min-width: 100px;
              &:hover,&:focus,&:active{
                background: #134f3d !important;
                color: #fff;
              }
              &.active{
                background: #134f3d !important;
                color: #fff;
              }
          }
          ul{
              display: flex;
              align-items: center;
              margin: 0px 0px 18px 0px;
              padding: 0px;
              li{
                  margin-right: 10px;
                  a{
                      color: #134e3d;
                      text-decoration: none;
                      font-family: "Rubik", sans-serif;
                      font-weight: 400;
                      font-size: 16px;
                  }
              }
          }
      }
      .ntf-list {
          &+.ntf-list {
              margin-top: 20px;
          }

          h3 {
              font-size: 18px;
              line-height: 26px;
              color: #000000;
              font-weight: 700;
              font-family: "Poppins", sans-serif;
              text-transform: capitalize;
              margin-top: 4px;
          }

          .nth-list-wrap {
              .nth-message-list {
                  filter: drop-shadow(0px 5px 6px rgba(0, 0, 0, 0.08));
                  background-color: #ffffff;
                  position: relative;
                  padding: 10px 25px;
                  margin-bottom: 15px;
                  &:hover{
                    background-color:#f9f9f9!important;
                }
                  .nth-ms-box {
                      display: flex;
                      align-items: flex-start;
                      justify-content: space-between;

                      .nth-msd {
                          display: flex;
                          align-items: center;
                          width: calc(100% - 170px);
                          .nth-profileicon {
                              margin-right: 25px;

                              img {
                                  width: 50px;
                                  height: 50px;
                                  object-fit: cover;
                                  object-position: center;
                                  border-radius: 50px;
                              }
                          }

                          .nth-message {
                              margin-right: 30px;

                              h2 {
                                  font-size: 17px;
                                  line-height: 20px;
                                  color: #404040;
                                  font-family: "Poppins", sans-serif;
                                  font-weight: 500;
                                  margin-bottom: 4px;

                                  span {
                                      color: #134e3d;
                                      font-weight: 700;
                                  }
                              }

                              p {
                                  font-size: 15px;
                                  line-height: 16px;
                                  color: #555555;
                                  font-weight: 400;
                                  font-family: "Poppins", sans-serif;
                                  margin-bottom: 0px;
                              }
                          }
                      }

                      .nth-time-ago {
                          span {
                              font-size: 12px;
                              line-height: 26px;
                              color: #777777;
                              font-weight: 400;
                              font-family: "Rubik", sans-serif;
                          }
                      }

                  }

                  .cls-btn {
                      width: 24px;
                      height: 24px;
                      border-radius: 12px;
                      background-color: #134e3d;
                      padding: 0px;
                      outline: none;
                      box-shadow: none;
                      border: none;
                      position: absolute;
                      top: -8px;
                      right: -8px;
                      display: flex;
                      align-items: center;
                      justify-content: center;

                      svg {
                          fill: #f6f6f6;
                      }
                  }
              }
          }
      }
  }
}

.not-notification {
  text-align: center;
  margin: 20px 0px;
}
.cursor{
  cursor: pointer;
}

.user-detail-wrapper{
  .profile-information{
    .personal-info{
      background-color: #f4f5fa;
      border: 1px solid #ededed;
      box-shadow: 0px 1px 3px #f4f5fa;
      ul{
        margin: 0px;
        padding: 16px;
        li{
          list-style: none;
          display: flex;
          align-items: flex-start;
          margin-bottom: 10px;
          &:last-child{
            margin-bottom: 0px;
          }
          .label{
            min-width: 200px;
            font-size: 15px;
            font-weight: 500;
            color: #3e3e3e;
          }
          .value{
            font-size: 15px;
            font-weight: 500;
          }
        }
      }
    }
  }
}
.clickable-td {
  cursor: pointer;
}