.login-wrapper {
  background: #f6f7fb;
  width: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  .loginn {
    width: 100%;
    max-width: 500px;
    border: none;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1), 0 8px 16px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    padding: 30px 30px;
    text-align: center;
    background: #ffffff;
    margin: auto;
    h2 {
      font-size: 28px;
      margin-bottom: 30px;
    }

    input {
      border-radius: 8px;
      border: 2px solid #dddfe2;
      outline: none;
      color: #1d2129;
      margin: 0px 0px 15px;
      padding: 0.5rem 0.75rem;
      width: 100%;
      font-size: 1rem;
    }
    textarea {
      border-radius: 8px;
      border: 2px solid #dddfe2;
      outline: none;
      color: #1d2129;
      margin: 0.1rem 0;
      padding: 0.5rem 0.75rem;
      width: 92%;
      font-size: 1rem;
    }
    .button {
      background: #fc1f1f;
      border: 1px solid #fc1f1f;
      box-shadow: none;
      color: #fff;
      font-size: 18px;
      padding: 0.5rem;
      margin: 0.5rem 0;
      border-radius: 8px;
      outline: none;
      cursor: pointer;
      max-width: 225px;
      width: 100%;
    }
    .forgetpassword {
      .forgetpassbtn {
        background: transparent;
        color: #212529;
        border: none;
        box-shadow: none;
        padding: 0px;
        font-weight: 600;
        font-size: 15px;
        position: relative;
      }
    }
  }
}

.loginn1 {
  width: 34%;
  background: #fff;
  border: 1px solid #dddfe2;
  box-shadow: 0 2px 4px rgb(0 0 0 / 10%), 0 8px 16px rgb(0 0 0 / 10%);
  border-radius: 8px;
  padding: 1rem;
  align-items: left;
  text-align: center;
  position: absolute;
  top: 12%;
  left: 65%;
  bottom: 2%;
  .loginSide {
    background-color: #fff;
  }
}
.forgetpassword {
  margin-top: 20px;
}
